<template>
  <div class="device-control-container">
    <div class="device-control-list-tools">
      <el-form :inline="true">
        <el-form-item label="设备ID:" v-if="search.organId === '99'">
          <el-select v-model="search.sn" filterable :style="{ width: '315px' }">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.pfsn"
              :value="item.pfsn"
              
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备ID:" v-else>
          <el-select v-model="search.sn" filterable :style="{ width: '315px' }">
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.sn"
              :value="item.sn"
              
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指令类型:">
          <el-select v-model="search.cmdType" style="width: 220px">
            <el-option
              v-for="(item, index) in cmdTypeList"
              :key="index"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指令状态:">
          <el-select v-model="search.status" style="width: 220px">
            <el-option
              v-for="(item, index) in statusList"
              :key="index"
              :label="item.value"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发生时间:">
          <el-date-picker
            v-model="search.times"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <div style="float: right">
          <el-form-item>
            <el-button type="primary" size="small" @click="getData"
              >查询</el-button
            >
            <el-button size="small" @click="resetFrom"
              >重置</el-button
            >
          </el-form-item>
        </div>
        <el-row>
          <el-form-item>
            <el-button type="primary" size="small" @click="setHandle()">设置</el-button>
          </el-form-item>  
        </el-row>
      </el-form>
    </div>
    <div class="device-control-list-content">
      <el-table
        class="mt20 ft14 c333 it1"
        style="width: 100%"
        height="100%"
        highlight-current-row
        stripe
        :data="list"
        key="MenuList"
      >
        <el-table-column v-if="search.organId === '99'" label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.pfsn || "-" }}
            </template>
          </el-table-column>
          <el-table-column v-else label="设备编号" prop="ompStatus">
            <template slot-scope="scope">
              {{ scope.row.sn || "-" }}
            </template>
          </el-table-column>
        <el-table-column prop="cmd" label="指令类型"> </el-table-column>
        <el-table-column prop="createTime" label="指令时间"> </el-table-column>
        <el-table-column prop="status" :formatter="statusStr" label="设备是否执行">
          
        </el-table-column>
        <el-table-column prop="createBy" label="操作人"> </el-table-column>
      </el-table>
    </div>

    <div class="device-control-page-container">
      <div class="device-control-page-content">
        <el-pagination
          background
          :current-page="search.pageIndex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="search.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import "./Index.less";
import axios from "@/utils/axios";
export default {
  name: "DeviceControl",
  data() {
    return {
      visible: false,
      total: 0,
      cmdTypeList: [],
      statusList: [],
      search: {
        cmdType: null,
        pageIndex: 1,
        pageSize: 20,
        endTime: null,
        orderBy: null,
        sn: null,
        startTime: null,
        status: null,
        times: [],
        organId: null,
      },
      list: [],
      deviceList: [],
    };
  },
  
  mounted() {
    this.search.organId = localStorage.getItem("organId");
    this.getCmdType();
    this.getStatusList();
    this.getData()
    this.getDeviceList()
  },
  methods: {
    //获取列表数据
    getData() {
      axios
        .post("/api/command/pageQuery", this.search)
        .then((res) => {
          this.total = res.total
          this.list = res.list
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取当前机构下所有设备
    getDeviceList() {
       axios
        .post("/api/device/getDevices", { id: this.search.organId })
        .then((rs) => {
          this.deviceList = rs;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //重置
    resetFrom() {
      this. search = {
         cmdType: null,
        pageIndex: 1,
        pageSize: 20,
        endTime: null,
        orderBy: null,
        sn: null,
        startTime: null,
        status: null,
        times: [],
        organId: localStorage.getItem("organId"),
      }
      this.getData()
    },
    //指令类型
    getCmdType() {
      axios
        .post("/api/command/select/cmdType", {})
        .then((res) => {
          this.cmdTypeList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //获取指令状态
    getStatusList() {
      axios
        .post("/api/command/select/status", {})
        .then((res) => {
          this.statusList = res;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.startTime = this.DateToStr(data[0]);
        this.search.endTime = this.DateToStr(data[1]);
      } else {
        this.search.startTime = null;
        this.search.endTime = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.search.pageIndex = val;
      this.getData();
    },
    setHandle() {
      this.$router.push({
        name: "SetupDetail",
      });
    },
    statusStr(item) {
      if(item.status === 1) {
        return '正常'
      } else if(item.status === 1) {
        return '异常'
      } else {
        return '-'
      }
    }
  },
};
</script>